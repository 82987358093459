<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step2') }}</div>
          <small class="text-muted">{{ $t('radius.wizardAdvancedParameters') }}</small>
          <form action="">
<!--            <div class="form-group">-->
<!--              <label for="secret">{{ $t('radius.key') }}</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                name="secret"-->
<!--                id="secret"-->
<!--                :placeholder="$t('radius.secretPlaceholder')"-->
<!--                class="form-control"-->
<!--                v-validate="'required|max:32'"-->
<!--                :class="{ input: true, 'is-danger': errors.has('secret') }"-->
<!--                v-model="newRadiusData.secret"-->
<!--              />-->
<!--              <span v-show="errors.has('secret')" class="help is-danger">{{ errors.first('secret') }}</span>-->
<!--            </div>-->

                                      <div class="form-group">
                <!--                    <label for="secret">{{ $t('radius.secret') }}</label>-->
                <label for="secret">{{ $t('radius.key') }}</label>
                <div class="d-flex w-100">
                  <div class="w-100">
                    <input
                      :type="maskRADIUSKey ? 'password' : 'text'"
                      @input="secretInputHandler"
                      :data-vv-as="$t('radius.key')"
                      name="secret"
                      ref="secret"
                      id="secret"
                      :placeholder="$t('radius.secretPlaceholder')"
                      class="form-control"
                      v-validate="'required|max:32'"
                      :class="{ input: true, 'is-danger': errors.has('secret') }"
                      v-model="newRadiusData.secret"
                    />
                  </div>
                  <div class="input-group-addon hovered" @click="maskRADIUSKey = !maskRADIUSKey">
                    <i class="fa fa-eye" v-if="maskRADIUSKey"></i>
                    <i class="fa fa-eye-slash" v-if="!maskRADIUSKey"></i>
                  </div>
                </div>
                <span v-show="errors.has('secret')" class="help is-danger">{{ errors.first('secret') }}</span>
              </div>

                          <div class="form-group">
                <!--                    <label for="secret">{{ $t('radius.secret') }}</label>-->
                <label for="secret">{{ $t('radius.keyConfirmation') }}</label>
                <div class="d-flex w-100">
                  <div class="w-100">
                    <input
                      :type="maskRADIUSKeyConfirmation ? 'password' : 'text'"
                      v-model="secretConfirmation"
                      @input="secretConfirmationInputHandler"
                      :data-vv-as="$t('radius.keyConfirmation')"
                      name="secret_confirmation"
                      id="secret_confirmation"
                      :placeholder="$t('radius.keyConfirmation')"
                      class="form-control"
                      v-validate="'required|confirmed:secret'"
                      :class="{ input: true, 'is-danger': errors.has('secret_confirmation') }"
                    />
                  </div>
                  <div class="input-group-addon hovered" @click="maskRADIUSKeyConfirmation = !maskRADIUSKeyConfirmation">
                    <i class="fa fa-eye" v-if="maskRADIUSKeyConfirmation"></i>
                    <i class="fa fa-eye-slash" v-if="!maskRADIUSKeyConfirmation"></i>
                  </div>
                </div>
                <div>
                  <span v-show="errors.has('secret_confirmation')" class="help is-danger">{{ errors.first('secret_confirmation') }}</span>
                </div>

              </div>


            <div class="form-group">
              <label for="auth">{{ $t('radius.authPort') }}</label>
              <input
                type="text"
                name="auth port"
                id="auth"
                :placeholder="$t('radius.authPortPlaceholder')"
                class="form-control"
                v-validate="'required|numeric|between:1,65535'"
                :class="{ input: true, 'is-danger': errors.has('auth port') }"
                v-model="newRadiusData.auth_port"
              />
              <span v-show="errors.has('auth port')" class="help is-danger">{{ errors.first('auth port') }}</span>
            </div>

            <div class="form-group">
              <label for="acct">{{ $t('radius.acctPort') }}</label>
              <input
                type="text"
                name="acct port"
                id="acct"
                :placeholder="$t('radius.acctPortPlaceholder')"
                class="form-control"
                v-validate="'required|numeric|between:1,65535'"
                :class="{ input: true, 'is-danger': errors.has('acct port') }"
                v-model="newRadiusData.acc_port"
              />
              <span v-show="errors.has('acct port')" class="help is-danger">{{ errors.first('acct port') }}</span>
            </div>

<!--            <h6 class="mt-3">{{ $t('radius.daeSettings') }}</h6>-->
            <h6 class="mt-2">{{ $t('radius.coaSettings') }}</h6>
<!--            &lt;!&ndash;                  dae_client&ndash;&gt;-->
<!--            <div class="form-group mt-1">-->
<!--              <label for="dae_client">-->
<!--                {{ $t('radius.daeClient') }}-->
<!--                <info :content="$t('radius.daeClientHintInfoMsg')" />-->
<!--              </label>-->
<!--              <input-->
<!--                :data-vv-as="$t('radius.daeClient')"-->
<!--                type="text"-->
<!--                name="dae_client"-->
<!--                id="dae_client"-->
<!--                :placeholder="$t('radius.daeClientPlaceholder')"-->
<!--                class="form-control"-->
<!--                v-validate="'radiusDaeClient'"-->
<!--                :class="{ input: true, 'is-danger': errors.has('dae_client') }"-->
<!--                v-model="newRadiusData.dae_client"-->
<!--                data-vv-validate-on="change"-->
<!--                @input="daeClientInputHandler"-->
<!--              />-->
<!--              <span v-show="errors.has('dae_client')" class="help is-danger">{{ errors.first('dae_client') }}</span>-->
<!--            </div>-->
<!--            &lt;!&ndash;                  dae_port&ndash;&gt;-->
<!--            <div class="form-group mt-1">-->
<!--              <label for="dae_client">-->
<!--                {{ $t('radius.daePort') }}-->
<!--                <info :content="$t('radius.daePortHintInfoMsg')" />-->
<!--              </label>-->
<!--              <input-->
<!--                :data-vv-as="$t('radius.daePort')"-->
<!--                type="text"-->
<!--                name="dae_port"-->
<!--                id="dae_port"-->
<!--                :placeholder="$t('radius.daePortPlaceholder')"-->
<!--                class="form-control"-->
<!--                v-validate="'numeric|between:1,65535'"-->
<!--                :class="{ input: true, 'is-danger': errors.has('dae_port') }"-->
<!--                v-model="newRadiusData.dae_port"-->
<!--                data-vv-validate-on="input"-->
<!--              />-->
<!--              <span v-show="errors.has('dae_port')" class="help is-danger">{{ errors.first('dae_port') }}</span>-->
<!--            </div>-->

<!--            &lt;!&ndash;                  dae_secret&ndash;&gt;-->
<!--            <div class="form-group mt-1">-->
<!--              <label for="dae_client">-->
<!--                {{ $t('radius.daeSecret') }}-->
<!--                <info :content="$t('radius.daeSecretHintInfoMsg')" />-->
<!--              </label>-->
<!--              <textarea-->
<!--                name="dae_secret"-->
<!--                id="dae_secret"-->
<!--                :placeholder="$t('radius.daeSecretPlaceholder')"-->
<!--                class="form-control"-->
<!--                :class="{ input: true, 'is-danger': errors.has('dae_secret') }"-->
<!--                v-model="newRadiusData.dae_secret"-->
<!--              />-->
<!--              <span v-show="errors.has('dae_secret')" class="help is-danger">{{ errors.first('dae_secret') }}</span>-->
<!--            </div>-->

<!--                          &lt;!&ndash;                  dae_secret&ndash;&gt;-->
<!--              <div class="form-group mt-1 d-flex flex-column">-->
<!--                <label for="dae_secret">-->
<!--                  {{ $t('radius.coaServerKey') }}-->
<!--                </label>-->
<!--                <div class="d-flex w-100">-->
<!--                  <div class="w-100" style="position: relative; background-color: transparent !important;">-->
<!--                    <div v-if="maskCoaServerKey"-->
<!--                         role="button"-->
<!--                         @click="overlayClickHandlerForCoaServerKey"-->
<!--                         style="position: absolute; background-color: transparent !important;"-->
<!--                         class="form-control  coa-server-key-fields coa-server-key_textareaOverlay d-flex justify-content-center align-items-center">-->
<!--                      <div class="h4">-->
<!--                        <i class="fa fa-eye-slash"/>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <textarea-->
<!--                      ref="dae_secret"-->
<!--                      @input="coaServerKeyInputHandler"-->
<!--                      :data-vv-as="$t('radius.coaServerKey')"-->
<!--                      name="dae_secret"-->
<!--                      v-validate="''"-->
<!--                      id="dae_secret"-->
<!--                      :placeholder="$t('radius.coaServerKey')"-->
<!--                      class="form-control coa-server-key-fields"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('dae_secret')}"-->
<!--                      v-model="newRadiusData.dae_secret"-->
<!--                    />-->
<!--                  </div>-->
<!--                  <div class="input-group-addon hovered"-->
<!--                       @click="maskCoaServerKey = !maskCoaServerKey">-->
<!--                    <i class="fa fa-eye" v-if="maskCoaServerKey"></i>-->
<!--                    <i class="fa fa-eye-slash" v-if="!maskCoaServerKey"></i>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                                  <span v-show="errors.has('dae_secret')" class="help is-danger">-->
<!--                      {{ errors.first('dae_secret') }}-->
<!--                    </span>-->
<!--                </div>-->

<!--              </div>-->

<!--              <div class="form-group mt-1 d-flex flex-column">-->
<!--                <label for="coaServerKeyConfirmation">-->
<!--                  {{ $t('radius.coaServerKeyConfirmation') }}-->

<!--                </label>-->
<!--                <div class="d-flex w-100">-->
<!--                  <div class="w-100" style="position: relative; background-color: transparent !important;">-->
<!--                    <div v-if="maskCoaServerKeyConfirmation"-->
<!--                         role="button"-->
<!--                         @click="overlayClickHandlerForCoaServerKeyConfirmation"-->
<!--                         style="position: absolute; background-color: transparent !important;"-->
<!--                         class="form-control  coa-server-key-fields coa-server-key_textareaOverlay d-flex justify-content-center align-items-center">-->
<!--                      <div class="h4">-->
<!--                        <i class="fa fa-eye-slash"/>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <textarea-->

<!--                      :data-vv-as="$t('radius.coaServerKeyConfirmation')"-->
<!--                      @input="coaServerKeyConfirmationInputHandler"-->
<!--                      name="coaServerKeyConfirmation"-->
<!--                      id="coaServerKeyConfirmation"-->
<!--                      :placeholder="$t('radius.coaServerKeyConfirmation')"-->
<!--                      v-validate="{ rules: { required: this.isCoaServerKeyConfirmationRequired, confirmed: 'dae_secret'} }"-->
<!--                      class="form-control coa-server-key-fields"-->
<!--                      :class="{ input: true, 'is-danger': errors.has('coaServerKeyConfirmation') }"-->
<!--                      v-model="coaServerKeyConfirmation"-->
<!--                    />-->

<!--                  </div>-->
<!--                  <div class="input-group-addon hovered"-->
<!--                       @click="maskCoaServerKeyConfirmation = !maskCoaServerKeyConfirmation">-->
<!--                    <i class="fa fa-eye" v-if="maskCoaServerKeyConfirmation"></i>-->
<!--                    <i class="fa fa-eye-slash" v-if="!maskCoaServerKeyConfirmation"></i>-->
<!--                  </div>-->


<!--                </div>-->
<!--                <div>-->
<!--                                    <span v-show="errors.has('coaServerKeyConfirmation')"-->
<!--                                          class="help is-danger">-->
<!--                      {{ errors.first('coaServerKeyConfirmation') }}-->
<!--                    </span>-->
<!--                </div>-->

<!--              </div>-->

            <!--                  dae_secret-->
              <div class="form-group mt-1 d-flex flex-column">
                <label for="dae_secret">
                  {{ $t('radius.coaServerKey') }}
                </label>
                <div class="d-flex w-100">
                  <div class="w-100" style="position: relative; background-color: transparent !important;">
<!--                    <div v-if="maskCoaServerKey"-->
<!--                         role="button"-->
<!--                         @click="overlayClickHandlerForCoaServerKey"-->
<!--                         style="position: absolute; background-color: transparent !important;"-->
<!--                         class="form-control  coa-server-key-fields coa-server-key_textareaOverlay d-flex justify-content-center align-items-center">-->
<!--                      <div class="h4">-->
<!--                        <i class="fa fa-eye-slash"/>-->
<!--                      </div>-->
<!--                    </div>-->
                    <input
                      :type="maskCoaServerKey ? 'password' : 'text'"
                      ref="dae_secret"
                      @input="coaServerKeyInputHandler"
                      :data-vv-as="$t('radius.coaServerKey')"
                      name="dae_secret"
                      v-validate="''"
                      id="dae_secret"
                      :placeholder="$t('radius.coaServerKey')"
                      class="form-control "
                      :class="{ input: true, 'is-danger': errors.has('dae_secret')}"
                      v-model="newRadiusData.dae_secret"
                    />
                  </div>
                  <div class="input-group-addon hovered"
                       @click="maskCoaServerKey = !maskCoaServerKey">
                    <i class="fa fa-eye" v-if="maskCoaServerKey"></i>
                    <i class="fa fa-eye-slash" v-if="!maskCoaServerKey"></i>
                  </div>
                </div>
                <div>
                                  <span v-show="errors.has('dae_secret')" class="help is-danger">
                      {{ errors.first('dae_secret') }}
                    </span>
                </div>

              </div>

              <div class="form-group mt-1 d-flex flex-column">
                <label for="coaServerKeyConfirmation">
                  {{ $t('radius.coaServerKeyConfirmation') }}

                </label>
                <div class="d-flex w-100">
                  <div class="w-100" style="position: relative; background-color: transparent !important;">
<!--                    <div v-if="maskCoaServerKeyConfirmation"-->
<!--                         role="button"-->
<!--                         @click="overlayClickHandlerForCoaServerKeyConfirmation"-->
<!--                         style="position: absolute; background-color: transparent !important;"-->
<!--                         class="form-control  coa-server-key-fields coa-server-key_textareaOverlay d-flex justify-content-center align-items-center">-->
<!--                      <div class="h4">-->
<!--                        <i class="fa fa-eye-slash"/>-->
<!--                      </div>-->
<!--                    </div>-->
                    <input
                      :type="maskCoaServerKeyConfirmation ? 'password' : 'text'"
                      :data-vv-as="$t('radius.coaServerKeyConfirmation')"
                      @input="coaServerKeyConfirmationInputHandler"
                      name="coaServerKeyConfirmation"
                      id="coaServerKeyConfirmation"
                      :placeholder="$t('radius.coaServerKeyConfirmation')"
                      v-validate="{ rules: { required: this.isCoaServerKeyConfirmationRequired, confirmed: 'dae_secret'} }"
                      class="form-control"
                      :class="{ input: true, 'is-danger': errors.has('coaServerKeyConfirmation') }"
                      v-model="coaServerKeyConfirmation"
                    />

                  </div>
                  <div class="input-group-addon hovered"
                       @click="maskCoaServerKeyConfirmation = !maskCoaServerKeyConfirmation">
                    <i class="fa fa-eye" v-if="maskCoaServerKeyConfirmation"></i>
                    <i class="fa fa-eye-slash" v-if="!maskCoaServerKeyConfirmation"></i>
                  </div>


                </div>
                <div>
                                    <span v-show="errors.has('coaServerKeyConfirmation')"
                                          class="help is-danger">
                      {{ errors.first('coaServerKeyConfirmation') }}
                    </span>
                </div>

              </div>


<!--            <div class="form-group mt-3">-->
<!--              <Switch-component-->
<!--                v-model="newRadiusData.is_local"-->
<!--                @change="newRadiusData.is_portal = false"-->
<!--                :label="$t('radius.clientOnAP')"-->
<!--                id="radius-local-new"-->
<!--                key="radius-local-new"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <Switch-component-->
<!--                v-model="newRadiusData.is_portal"-->
<!--                :disabled="newRadiusData.is_local"-->
<!--                :label="$t('radius.clientOnWLC')"-->
<!--                id="radius-portal-new"-->
<!--                key="radius-portal-new"-->
<!--              />-->
<!--              <Info class="ml-h" :content="$t('radius.clientOnWLCHint')" ></Info>-->
<!--            </div>-->

            <div class="form-group">
                <Switch-component
                  @input="clientOnAPClientOnWLCSwitcherHandler"
                  :label-before-switcher="$t('radius.clientOnAP')"
                  v-model="clientOnAPClientOnWLCSwitcherState"
                  :label="$t('radius.clientOnWLC')"
                  id="radius-portal"
                />
                <Info class="ml-h" :content="$t('radius.clientOnWLCHint')" ></Info>
              </div>
            <!--auth/acct/both-->
            <WButton primary outline
              customClass="radius-wizard-next-button float-right"
              @click="setNewRadiusData"
              :disabled="errors.any()"
            >
              {{ $t('general.next') }}
            </WButton>
            <WButton danger outline
              customClass="radius-wizard-back-button float-left"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </WButton>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import Info from '../../components/Universal/info-icon.vue';

export default {
  name: 'RadiusStep2',
  components: {
    SwitchComponent,
    Info
  },
  data() {
    return {
      maskCoaServerKeyConfirmation: true,
      maskCoaServerKey: true,
      maskRADIUSKeyConfirmation: true,
      maskRADIUSKey: true,
      secretConfirmation: '',
      coaServerKeyConfirmation: '',
      newRadiusData: {
        secret: '',
        auth_port: '1812',
        acc_port: '1813',
        dae_client: '',
        // dae_port: '3799',
        // теперь не ставим по умолчанию, так как вообще обрали это поле из UI (DF-65)
        dae_port: '',
        dae_secret: ''
      }
    };
  },
  computed: {
    clientOnAPClientOnWLCSwitcherState: {
      get() {
        try {
          if (this.newRadiusData.is_local === true && this.newRadiusData.is_portal === false) {
            return false;
          } else if (this.newRadiusData.is_local === false && this.newRadiusData.is_portal === true) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      },
      set(newVal) {
        // console.log(newVal)
      }
    },
    isCoaServerKeyConfirmationRequired() {
      try {
        if (this.newRadiusData.dae_secret !== '' && this.newRadiusData.dae_secret !== undefined) {
          return true;
        } else {
          return false
        }
      } catch (e) {
        console.log(e)
        return false
      }
    },
  },
  methods: {
    clientOnAPClientOnWLCSwitcherHandler(value) {
      // console.log('clientOnAPclientOnWLCSwitcherHandler value', value)
      if (value === false) {
        // console.log('setClientOnAP')
        this.setClientOnAP();
      } else if (value === true) {
        // console.log('setClientOnWLC')
        this.setClientOnWLC();
      }
    },
    setClientOnAP() {
      this.newRadiusData.is_local = true;
      this.newRadiusData.is_portal = false;
    },
    setClientOnWLC() {
      this.newRadiusData.is_local = false;
      this.newRadiusData.is_portal = true;
    },
    overlayClickHandlerForCoaServerKey() {
      this.maskCoaServerKey = false;
      document.getElementById('dae_secret').focus();
    },
    overlayClickHandlerForCoaServerKeyConfirmation() {
      this.maskCoaServerKeyConfirmation = false;
      document.getElementById('coaServerKeyConfirmation').focus();
    },
    maskAllKeysFields() {
      this.maskCoaServerKeyConfirmation = true;
      this.maskCoaServerKey = true;
      this.maskRADIUSKeyConfirmation = true;
      this.maskRADIUSKey = true;
    },
    unmaskAllKeysFields() {
      this.maskCoaServerKeyConfirmation = false;
      this.maskCoaServerKey = false;
      this.maskRADIUSKeyConfirmation = false;
      this.maskRADIUSKey = false;
    },

    secretConfirmationInputHandler(){
      // console.log('here')
      // this.clearErrorsForInput('secret_confirmation');
      this.$validator.validate('secret_confirmation');
    },
    secretInputHandler() {
      this.$validator.validate('secret_confirmation');
    },
    coaServerKeyConfirmationInputHandler() {
      this.$validator.validate('coaServerKeyConfirmation');
    },
    coaServerKeyInputHandler() {
      this.$validator.validate('coaServerKeyConfirmation');
    },
    clearErrorsForInput(inputName) {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has(inputName)) {
          this.errors.remove(inputName);
        }
      }
    },
    daeClientInputHandler(e) {
      // очищаем ошибку dae_client, если она есть, каждый раз когда начанаем вводить dae_client
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('dae_client')) {
          // console.log('has dae_client error');
          this.errors.remove('dae_client');
        }
      }
    },
    setNewRadiusData() {
      if (this.newRadiusData.dae_port === '') {
        // если ничего не ввели ставим в значение по умолчанию
        // this.newRadiusData.dae_port = '3799';

        // теперь не ставим по умолчанию, так как вообще обрали это поле из UI (DF-65)

      }
      this.$validator
        .validateAll({
          secret: this.newRadiusData.secret,
          'auth port': this.newRadiusData.auth_port,
          'acct port': this.newRadiusData.acc_port,
          'secretConfirmation': this.secretConfirmation,
          'dae_secret': this.newRadiusData.dae_secret,
          'coaServerKeyConfirmation': this.coaServerKeyConfirmation
        })
        .then(
          (result) => {
            if (result) {
              this.$store.commit('setNewRadiusData', this.newRadiusData);
              this.$parent.nextStep();
            }
          },
          () => {
            console.warn('Validation failed');
          }
        );
    }
  },
  created() {
    this.unmaskAllKeysFields();
    this.newRadiusData = JSON.parse(JSON.stringify(this.$store.state.newRadiusData));
    if (this.newRadiusData.secret) {
      this.maskAllKeysFields();
      this.secretConfirmation = this.newRadiusData.secret
    }
    if (this.newRadiusData.dae_secret){
      this.coaServerKeyConfirmation = this.newRadiusData.dae_secret
    }
  }
};
</script>
<style scoped lang="scss">
.radius-wizard-next-button {
  top: 10px;
  position: relative;
}

.radius-wizard-back-button {
  top: 10px;
  position: relative;
}
    .coa-server-key-fields {
    height: 10em !important;
  }

  .coa-server-key_textareaOverlay {
    /*color: transparent;*/
    background-color: transparent;
    backdrop-filter: blur(2px)
  }
</style>

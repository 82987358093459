<template>
  <div class="row" v-if="this.$store.state.userData.role !== 'operator'">
    <div class="col-lg-3">
      <div class="card card-inverse card-warning">
        <div class="card-block">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-wlan-icon" :src="'/static/img/wifi.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('radius.radiusWizard') }}</div>
          <div v-if="currentStepIndex === '1'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step1') }}</p>
            <p>{{ $t('radius.basicParameters') }}</p>
          </div>
          <div v-if="currentStepIndex === '2'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step2') }}</p>
            <p>{{ $t('radius.advancedSettings') }}</p>
          </div>
          <div v-if="currentStepIndex === '3'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step3') }}</p>
            <p>{{ $t('radius.confirmRadius') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4"><router-view></router-view></div>
  </div>
</template>

<script>
export default {
  name: 'RadiusWizard',
  computed: {
    currentStepIndex() {
      return this.$route.name.replace('radius-step-', '');
    }
  },
  methods: {
    nextStep() {
      let nextStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'radius-step-1':
          nextStep = 'radius-step-2';
          break;
        case 'radius-step-2':
          nextStep = 'radius-step-3';
          break;
        case 'radius-step-3':
          nextStep = 'Radius';
          break;
        default:
          nextStep = 'Radius';
      }
      this.$router.push({ name: nextStep });
    },
    prevStep() {
      let prevStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'radius-step-1':
          prevStep = 'Radius';
          if (this.$store.state.stepNameForReturningToCreateWLANWizard) {
            prevStep = 'wlanWizardV2';
          } else if (this.$store.state.returnToEditWlan) {
            prevStep = 'Wlans';
          }
          break;
        case 'radius-step-2':
          prevStep = 'radius-step-1';
          break;
        case 'radius-step-3':
          prevStep = 'radius-step-2';
          break;
        default:
          prevStep = 'Radius';
      }
      this.$router.push({ name: prevStep });
    }
  }
};
</script>
<style lang="scss">
.wizard-wlan-icon {
  height: 80px;
}
</style>
